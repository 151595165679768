import { surface } from 'config/injection_keys';

export default {
  inject: {
    surface: {
      from: surface,
      default: 'bg',
    },
  },
};
