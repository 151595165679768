import _ from 'lodash';
import httpClient from '../utils/http_client';
import { snakeCase } from '../utils/convert_keys';

export default {
  async index(params) {
    const response = await httpClient.get('/members', { params });

    return response.data;
  },

  async create(params) {
    const response = await httpClient.post('/members', params);

    return response.data;
  },

  async update(id, params) {
    const response = await httpClient.put(`/members/${id}`, params);

    return response.data;
  },

  async destroy(id) {
    const response = await httpClient.delete(`/members/${id}`);

    return response.data;
  },

  async resendInvitation(id) {
    const response = await httpClient.post(`/members/${id}/resend_invitation`);

    return response.data;
  },

  async fetchCurrentData(token) {
    const response = await httpClient.get(`/accept_invitations/fetch_current_data/${token}`);

    return response.data;
  },

  async register({ token, params }) {
    const formData = new FormData();

    if (!params.avatar) {
      delete params.avatar;
    }

    _.each(params, (value, key) => formData.append(snakeCase(key), value));
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const response = await httpClient.put(`accept_invitations/register/${token}`, formData, config);

    return response.data;
  },
};
