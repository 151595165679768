<template>
  <div>
    <div class="overlay__title">
      {{ $t('two_fa.lets_setup') }}
    </div>
    <div class="overlay__text">
      {{ $t('two_fa.scan_code') }}<br>
      <span v-html="$t('two_fa.download')" />
    </div>
    <div class="code__container">
      <canvas ref="qrCode" />
    </div>
    <div class="overlay__form-wrapper">
      <InputBuilder
        :value="value"
        :required="true"
        :label="$t('two_fa.authentication_code')"
        :placeholder="$t('two_fa.enter_code')"
        :errors="errors"
        :autocomplete="false"
        class="input-settings"
        name="code"
        @input="$emit('input', $event)"
      />
      <div class="overlay__button-wrapper">
        <Button
          :label="$t('two_fa.activate')"
          :loading="loading"
          :disabled="!value"
          class="button button__primary"
          @click.native="$emit('submit')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from 'qrcode';
import InputBuilder from '../common/inputs/InputBuilder.vue';
import Button from '../common/buttons/Button.vue';

export default {
  components: {
    InputBuilder,
    Button,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      required: true,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    qr: {
      type: String,
      required: true,
    },
  },
  mounted() {
    QRCode.toCanvas(this.$refs.qrCode, this.qr, { color: { light: '#F5F7F9' } });
  },
};
</script>
<style lang="scss" scoped>
  .overlay__text ::v-deep a {
    font-weight: 500;
    color: #3152dc;
  }

  .code__container {
    width: 250px;
    height: 250px;
    border-radius: 12px;
    background-color: #f5f7f9;
    margin: 20px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
