import Vue from 'vue';
import VueGtag from 'vue-gtag';

if (process.env.RAILS_ENV === 'production') {
  Vue.use(VueGtag, {
    config: { id: 'G-ZCCJ1HMDKQ' },
    includes: [
      { id: 'AW-10818029567' },
    ],
  }, Vue.router);
}
