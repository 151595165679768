<template>
  <div>
    <div class="overlay__title">
      {{ $t('two_fa.authentication_code') }}
    </div>
    <div class="overlay__text">
      {{ $t('two_fa.sent_sms') }}
    </div>
    <div class="overlay__form-wrapper">
      <InputBuilder
        :value="value"
        :required="true"
        :label="$t('two_fa.authentication_code')"
        :placeholder="$t('two_fa.enter_code')"
        :errors="errors"
        :autocomplete="false"
        class="input-settings"
        name="code"
        @input="$emit('input', $event)"
      />
      <div class="overlay__button-wrapper">
        <Button
          :label="buttonLabel"
          :loading="loading"
          :disabled="!value"
          class="button button__primary"
          @click.native="$emit('submit')"
        />
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
import InputBuilder from '../common/inputs/InputBuilder.vue';
import Button from '../common/buttons/Button.vue';

export default {
  components: {
    InputBuilder,
    Button,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      required: true,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    buttonLabel: {
      type: String,
      required: true,
    },
  },
};
</script>
