<template>
  <div v-if="notice" class="notice show" :class="[{'notice--closable': notice.closable}, noticeClass]">
    {{ notice.body }}
    <a
      v-if="notice.link"
      :href="notice.link"
      class="notice__link"
      @click="closeNotice"
    >{{ notice.linkLabel }}</a>
    <img
      v-if="notice.closable"
      src="../../../assets/images/base-icons/close.svg?fill=%2338d274"
      class="notice__close"
      @click="closeNotice"
    >
  </div>
</template>
<script>
export default {
  computed: {
    notice() {
      return this.$quickNotice.notice;
    },
    noticeClass() {
      return `notice--${this.notice.type}`;
    },
  },
  methods: {
    closeNotice() {
      this.$quickNotice.clear();
    },
  },
};
</script>
