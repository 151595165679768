<template>
  <div v-if="condition" :style="keepHeight ? `height: ${height}px` : ''" class="loading">
    <div class="spinner" />
    <div v-if="showLabel" class="loading-text">
      {{ label || $t('loading') }}
    </div>
  </div>
  <div v-else ref="container" class="loading__slot">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    condition: {
      type: Boolean,
      default: true,
    },
    keepHeight: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      height: null,
    };
  },
  watch: {
    condition() {
      this.setListener();
    },
  },
  created() {
    this.setListener();
  },
  methods: {
    resize() {
      if (this.$refs.container) {
        this.height = this.$refs.container.clientHeight;
      }
    },
    setListener() {
      if (!this.condition) {
        this.$nextTick(() => {
          if (this.$refs.container) {
            this.$refs.container.addEventListener('resize', this.resize);
            this.resize();
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .loading {
    @apply w-full flex items-center justify-center text-lg min-h-[250px] flex-col;

    &__slot {
      @apply flex-grow flex flex-col;
    }

    &.loading--blue-bg {
      .loading-text {
        color: #f9fafc;
      }

      .spinner {
        background: url('../../../assets/images/loaders/loading-spinner-white.svg') no-repeat;
      }
    }

    &.loading--small {
      min-height: 100px;
    }

    &.loading--full {
      height: 100%;
    }
  }

  .loading-text {
    font-size: 14px;
    text-align: center;
    color: #3152dc;
    margin-top: 30px;
    font-weight: 500;
  }

  .spinner {
    animation: rotate 1s linear infinite;
    background: url('../../../assets/images/loaders/loading-spinner.svg') no-repeat;
    height: 30px;
    width: 30px;
  }

  .loading--without-min-height {
    min-height: 0;
  }
</style>
