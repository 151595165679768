<template>
  <div class="wrapper">
    <v-select
      v-model="country"
      :options="countries"
      label="dialCode"
      :filter="filter"
      :name="`${name}Code`"
      class="v-select--bordered v-select--input-prefix"
      @input="update"
    >
      <template slot="option" slot-scope="option">
        <span class="iti-flag" :class="option.iso2.toLowerCase()" />
        {{ option.name }}
        (+{{ option.dialCode }})
      </template>
      <template slot="selected-option" slot-scope="option">
        +{{ option.dialCode }}
      </template>
    </v-select>
    <input
      v-model="phone"
      :placeholder="placeholder"
      :name="name"
      class="phone-number"
      @input="update"
    >
    <div class="clearfix" />
  </div>
</template>
<script>
import _ from 'lodash';
import countries from '../../../../utils/countries';

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      countryCode: '',
      phone: '',
    };
  },
  computed: {
    country: {
      get() {
        return _.find(countries, { dialCode: this.countryCode });
      },
      set(country) {
        this.countryCode = country.dialCode;
      },
    },
    countries() {
      return _.sortBy(countries, 'priority');
    },
  },
  watch: {
    value(newVal) {
      this.splitValue(newVal);
    },
  },
  created() {
    this.splitValue(this.value);
  },
  methods: {
    update() {
      this.$emit('input', `+${this.countryCode}-${this.phone}`);
    },
    splitValue(newVal) {
      if (!newVal) {
        return;
      }
      const split = newVal.replace('+', '').split('-');

      if (split.length < 2) {
        return;
      }

      this.countryCode = split[0];

      if (split.length > 1) {
        this.phone = split.slice(1).join('-');
      }
    },
    filter(options, search) {
      return _.filter(options, (option) => {
        const string = `${option.name} +${option.dialCode}`;
        return string.toLowerCase().indexOf(search.toLowerCase()) > -1;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
  @import 'app/javascript/assets/styles/flags-sprite';

  .wrapper {
    box-sizing: border-box;
    position: relative;
  }

  .iti-flag {
    display: inline-block;
  }

  .phone-number {
    height: 44px;
    padding: 5px 20px;
  }

  .clearfix {
    clear: both;
  }
</style>
