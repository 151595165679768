<template>
  <button :class="{'button--loading': loading}">
    <slot>
      {{ label }}
    </slot>
  </button>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
  button {
    cursor: pointer;
    font-weight: 500;

    &:focus {
      outline: 0;
    }
  }
</style>
