import vSelect from 'vue-select';

export default {
  extends: vSelect,
  props: {
    maxHeight: {
      type: String,
      default: '250px',
    },
  },
  data() {
    return {
      reversed: false,
    };
  },
  watch: {
    open(value) {
      if (value) {
        this.reversed = false;
        this.$nextTick(() => {
          const pos = this.$refs.dropdownMenu.getBoundingClientRect();
          if (pos.top + pos.height > window.innerHeight) {
            this.reversed = true;
          }
        });
      }
    },
  },
  computed: {
    dropdownClasses() {
      return {
        open: this.dropdownOpen,
        single: !this.multiple,
        searching: this.searching,
        searchable: this.searchable,
        unsearchable: !this.searchable,
        loading: this.mutableLoading,
        rtl: this.dir === 'rtl',
        disabled: this.disabled,

        // only this one is added, the rest is from vue-select
        reversed: this.reversed,
      };
    },
  },
  methods: {
    // remove scrolling to selected option (it worked weird in reversed mode)
    maybeAdjustScroll() {},
  },
};
