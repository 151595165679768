import _ from 'lodash';

class QuickNotice {
  noticeTimer = null;
  notice = null;

  clear() {
    this.notice = null;
  }

  notify(data) {
    const noticeData = _.defaults({}, data, { timer: true });

    this.notice = noticeData;

    if (this.noticeTimer) {
      clearTimeout(this.noticeTimer);
    }

    if (noticeData.timer) {
      this.noticeTimer = setTimeout(() => {
        this.clear();
      }, 5000);
    }
  }
}

export default {
  install(Vue) {
    const quickNotice = Vue.observable(new QuickNotice());
    Vue.quickNotice = quickNotice;
    Vue.prototype.$quickNotice = quickNotice;
  },
};
