<template>
  <div>
    <InputBuilder
      name="email"
      :value="value"
      :label="label"
      :placeholder="placeholder"
      :errors="errors"
      class="input-settings"
      :required="required"
      @input="updateValue"
    />
    <div v-if="emailValidation && emailSuggested" class="form__email-validate">
      {{ $t('register.email.validate') }}
      <span class="form__email-validate--email" @click="updateValue(suggestedFirst.corrected)">
        {{ emailBase }}@<span class="form__email-validate--domain">{{ emailDomain }}</span>
      </span>?
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import emailMisspelled, { top100 } from 'email-misspelled';
import InputBuilder from './InputBuilder.vue';

const emailChecker = emailMisspelled({ domains: top100 });

export default {
  components: { InputBuilder },
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    emailValidation: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    suggestedFirst() {
      return emailChecker(this.value)[0];
    },
    emailSuggested() {
      if (this.suggestedFirst === undefined) return false;
      return this.suggestedFirst.corrected !== this.value;
    },
    emailBase() {
      return _.split(this.suggestedFirst.original, '@')[0];
    },
    emailDomain() {
      return this.suggestedFirst.suggest;
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
  .form__email-validate {
    margin-top: -25px;
    text-align: right;
    margin-bottom: 15px;
    font-size: 12px;
  }

  .form__email-validate--email {
    color: #2e4ce0;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }

  .form__email-validate--domain {
    font-weight: 500;
  }
</style>
