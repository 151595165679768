<template>
  <div class="avatar-picker">
    <div class="image-preview" :style="`background-image: url(&quot;${fileForPreview || placeholder}&quot;)`" />

    <input
      ref="file"
      type="file"
      name="avatar"
      class="file-input"
      @change="fileChanged"
    >

    <div class="avatar-picker__buttons-container">
      <div v-if="fileForPreview" class="avatar-picker__links-container">
        <div class="avatar-picker__upload-link" @click="uploadImage">
          {{ $t('profile.avatar.upload') }}
        </div>
        <div class="avatar-picker__dot">
          •
        </div>
        <div class="avatar-picker__remove-link" @click="removeFile">
          {{ $t('profile.avatar.remove') }}
        </div>
      </div>
      <div v-else>
        <Button
          :label="$t('profile.avatar.upload')"
          class="button__primary button__upload"
          @click.native="uploadImage"
        />
      </div>

      <div class="avatar-picker__instructions">
        <div class="avatar-picker__instructions--format">
          {{ $t('profile.avatar.formats') }}
        </div>
        <div class="avatar-picker__instructions--resolution">
          {{ $t('profile.avatar.resolution') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '../buttons/Button.vue';
import placeholder from '../../../../assets/images/backgrounds/avatar-placeholder.svg';
import UploadImageHelper from '../../../../mixins/upload_image_helper';

export default {
  components: {
    Button,
  },
  mixins: [UploadImageHelper],
  props: {
    /* eslint-disable vue/require-prop-types */
    value: {
      default: null,
    },
    avatar: {
      default: null,
    },
    /* eslint-enable vue/require-prop-types */
    placeholder: {
      type: String,
      default: placeholder,
    },
  },
  computed: {
    fileForPreview() {
      return this.previewFile || (this.file !== '' && this.avatar);
    },
  },
  methods: {
    fileChanged() {
      this.verifyUploadedFile();
      if (this.file) {
        this.$emit('input', this.file);
      }
    },
    removeFile() {
      this.file = '';
      this.previewFile = '';
      this.$emit('input', this.file);
    },
  },
};
</script>
<style lang="scss" scoped>
  .avatar-picker {
    display: flex;
    margin-bottom: 60px;
    align-items: center;

    @media only screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  .file-input {
    display: none;
  }

  .image-preview {
    width: 100px;
    height: 100px;
    overflow: hidden;
    background-color: #fcfdfd;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 8px;
    margin-right: 20px;
    flex-shrink: 0;
  }

  .avatar-picker--contain {
    .image-preview {
      background-size: contain;
    }
  }

  .avatar-picker__buttons-container {
    display: flex;
    flex-flow: column;
  }

  .avatar-picker__links-container {
    color: #8798ac;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    line-height: 12px;
    margin-bottom: 10px;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  .avatar-picker__upload-link,
  .avatar-picker__remove-link {
    cursor: pointer;

    &:active {
      display: inline-block;
      outline: none;
      transform: translateY(2px);
    }
  }

  .avatar-picker__upload-link {
    color: #3152dc;
    margin-right: 10px;

    &:hover {
      color: #1335c3;
    }

    @media only screen and (max-width: 767px) {
      margin: 0 0 14px;
    }
  }

  .avatar-picker__dot {
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  .avatar-picker__remove-link {
    color: #ff2373;
    margin-left: 10px;

    &:hover {
      color: #e20052;
    }

    @media only screen and (max-width: 767px) {
      margin-left: 0;
    }
  }

  .button__upload {
    background: url('../../../../assets/images/arrows/arrow-up-thick.svg') no-repeat 31px 12px #3152dc;
    background-size: 14px;
    color: #e5ebf0;
    font-size: 14px;
    line-height: 10px;
    font-weight: 500;
    padding: 15px 30px 15px 50px;
  }

  .avatar-picker__instructions {
    color: #8798ac;
    font-size: 10px;
    margin-top: 5px;
    line-height: 16px;
  }
</style>
