export default {
  data() {
    return {
      file: null,
      previewFile: null,
    };
  },
  methods: {
    uploadImage() {
      this.$refs.file.click();
    },
    verifyUploadedFile() {
      this.file = this.$refs.file.files[0];
      this.$refs.file.value = '';

      const reader = new FileReader();

      reader.addEventListener('load', () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          if (image.width < 200 || image.height < 200) {
            this.$quickNotice.notify({ body: this.$t('profile.avatar.resolution'), type: 'error' });
            this.file = null;
          } else {
            this.previewFile = reader.result;
          }
        };
      });

      if (this.file) {
        if (!/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          this.$quickNotice.notify({ body: this.$t('profile.avatar.select_image'), type: 'error' });
          this.file = null;
          return;
        }

        if (this.file.size > 10 * 1024 * 1024) {
          this.$quickNotice.notify({ body: this.$t('profile.avatar.file_too_big'), type: 'error' });
          this.file = null;
          return;
        }

        reader.readAsDataURL(this.file);
      }
    },
  },
};
