import httpClient from '../utils/http_client';

export default {
  async get() {
    const response = await httpClient.get('/measure_account');

    return response.data;
  },

  async update(params) {
    const response = await httpClient.patch('/measure_account', params);

    return response.data;
  },

  async destroy({ password }) {
    const response = await httpClient.post('/measure_account', { password },
      { headers: { 'X-Http-Method-Override': 'delete' } });

    return response.data;
  },

  async detach({ password }) {
    const response = await httpClient.post('/measure_account/detach', { password },
      { headers: { 'X-Http-Method-Override': 'delete' } });

    return response.data;
  },

  async updateDashboard(params) {
    const response = await httpClient.post('/measure_account/update_dashboard', params);

    return response.data;
  },

  async updateTutorialStatus() {
    const response = await httpClient.put('/measure_account/update_tutorial');

    return response.data;
  },

  async getByLoginSlug(loginPageSlug) {
    const response = await httpClient.get(`/auth/login/measure_accounts/${loginPageSlug}`);

    return response.data;
  },
};
