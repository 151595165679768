<template>
  <label
    class="checkbox"
    :class="[{
      'checkbox--semi-selected': semiSelected && value,
      'checkbox--disabled': disabled,
      'checkbox--checked': value
    }, `checkbox--surface-${surface}`]"
  >
    <input
      :id="id"
      :name="name"
      :value="value"
      :checked="value"
      :disabled="disabled"
      type="checkbox"
      @change="updateValue($event.target.checked)"
    >
    <span class="checkbox__box" />

    <div v-if="description" class="checkbox__label--wrapper">
      <span class="checkbox__label">
        <slot>{{ label }}</slot>
      </span>

      <span class="checkbox__description">
        <slot>{{ description }}</slot>
      </span>
    </div>

    <span v-else-if="label || $slots.default" class="checkbox__label">
      <slot>{{ label }}</slot>
    </span>
  </label>
</template>

<script>
import SurfaceHelper from 'mixins/surface_helper';

export default {
  mixins: [SurfaceHelper],
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    id: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // semi selected works correctly only if value == true
    semiSelected: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style lang="scss" scoped>
  input[type="checkbox"] {
    position: absolute;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkbox__label--wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1px;
  }

  .checkbox__label {
    margin-left: 8px;
    display: inline-block;
    flex-grow: 1;
    margin-top: 4px;
    color: #1b2938;
    line-height: 18px;
    overflow: hidden;
  }

  .checkbox__description {
    margin-left: 8px;
    display: inline-block;
    flex-grow: 1;
    margin-top: 4px;
    color: #8798AC;
    line-height: 18px;
    overflow: hidden;
    font-size: 12px;
    font-weight: 400;
  }

  .checkbox__box {
    content: "";
    display: inline-block;
    background: url('../../../../assets/images/checkboxes/checkbox.svg') transparent;
    background-size: 24px 24px;
    height: 24px;
    width: 24px;
    flex-shrink: 0;
  }

  .checkbox:hover .checkbox__box {
    background-image: url('../../../../assets/images/checkboxes/checkbox-hover.svg');
  }

  .checkbox.checkbox--checked {
    .checkbox__label {
      font-weight: 500;
    }

    .checkbox__box {
      background-image: url('../../../../assets/images/checkboxes/checkbox-checked.svg');
    }
  }

  .checkbox.checkbox--transparent.checkbox--checked {
    .checkbox__box {
      background-image: url('../../../../assets/images/checkboxes/checkbox-checked-transparent.svg');
    }
  }

  .checkbox.checkbox--disabled {
    .checkbox__label {
      color: #c6cfd6;
    }

    .checkbox__box {
      background-image: url('../../../../assets/images/checkboxes/checkbox-disabled.svg');
    }
  }

  .checkbox.checkbox--semi-selected.checkbox--checked {
    .checkbox__box {
      background-image: url('../../../../assets/images/checkboxes/checkbox-semi.svg');
    }
  }

  .checkbox.checkbox--semi-selected.checkbox--checked.checkbox--disabled {
    .checkbox__box {
      background-image: url('../../../../assets/images/checkboxes/checkbox-semi-disabled.svg');
    }
  }

  .checkbox.checkbox--checked.checkbox--disabled {
    .checkbox__box {
      background-image: url('../../../../assets/images/checkboxes/checkbox-checked-disabled.svg');
    }
  }

  .checkbox.checkbox--radio {
    .checkbox__box {
      background-image: url('../../../../assets/images/radioboxes/radiobox.svg');
    }

    &:hover .checkbox__box {
      background-image: url('../../../../assets/images/radioboxes/radiobox-hover.svg');
    }

    &.checkbox--checked {
      .checkbox__box {
        background-image: url('../../../../assets/images/radioboxes/radiobox-checked.svg');
      }
    }

    &.checkbox--transparent.checkbox--checked {
      .checkbox__box {
        background-image: url('../../../../assets/images/radioboxes/radiobox-checked-transparent.svg');
      }
    }

    &.checkbox--disabled {
      .checkbox__box {
        background-image: url('../../../../assets/images/radioboxes/radiobox-disabled.svg');
      }
    }

    &.checkbox--checked.checkbox--disabled {
      .checkbox__box {
        background-image: url('../../../../assets/images/radioboxes/radiobox-checked-disabled.svg');
      }
    }
  }

  .checkbox {
    display: flex;
    flex-direction: row;
    cursor: pointer;

    &--center-vertical {
      align-items: center;

      .checkbox__label {
        margin-top: 0;
      }
    }

    &--search-bar {
      padding-top: 11px;
      width: fit-content;
    }

    &--surface-accent #{&}__label {
      color: #f0f4f8;
    }

    &--surface-accent#{&}--checked #{&}__box {
      background-image: url('../../../../assets/images/checkboxes/checkbox-checked.svg?fill=#0121A8 .tick');
    }
  }
</style>
